/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/header.css"
import "./src/styles/form.css"
import "./src/styles/services.css"
import "./src/styles/boxContent.css"
import "./src/styles/aside.css"
import "./src/styles/aboutMe.css"
